// method to emit event when a section enter or leave screen
export const intersectionHandler = {

    methods: {
        intersectionHandler( entries ){
            if (entries[0].isIntersecting){
                this.$emit('isEntering',{sectionId:entries[0].target.id})
            }
        }
    }

}