<template>
<section
    :id="id"
    class="pt-3"
    v-intersect="{
        handler: intersectionHandler,
        options: {
            threshold: 0,
            rootMargin: '-49% 0px -49% 0px'
        }
    }"
>
<v-container>

    <!-- title -->
    <v-row>
        <v-col>
            <h2 class="text-h4 text-sm-h3 font-weight-medium text-center text-md-left">{{ title }}</h2>
        </v-col>
    </v-row>

    <v-row>

        <v-col cols="12" sm="8" offset-sm="2" md="5" offset-md="0" class="d-flex flex-column">

            <!-- testimony -->
            <v-row class="flex-grow-0">
                <v-col class="mb-3">
                    <!-- photo -->
                    <v-img
                        :src="pathToAsset(testimony.author.photo.src)"
                        :alt="testimony.author.photo.alt"
                        width="80" height="80"
                        class="rounded-circle float-left mr-3 elevation-5"
                        cover
                        eager
                    ></v-img>
                    <!-- quote -->
                    <v-icon size="50" color="shineRed">mdi-format-quote-close</v-icon>
                    <!-- text -->
                    <p class="text-body-2 text-justify my-1">{{ testimony.text }}</p>
                    <!-- author -->
                    <p class="text-body-1 font-weight-medium text-right mb-0" style="color: #ED1847">
                        {{ testimony.author.name }}<br>
                        <span class="font-italic">{{ testimony.author.function }}</span>
                    </p>
                </v-col>
            </v-row>

            <!-- map -->
            <v-row class="flex-grow-1">
                <v-col cols="12" class="d-flex align-center">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d103034.18335963954!2d5.949484074648131!3d47.264822607383955!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x80f00bbfd2cd2620!2sShine%20Research!5e0!3m2!1sfr!2sfr!4v1603891292893!5m2!1sfr!2sfr"
                        width="80%"
                        :height="$vuetify.breakpoint.mdAndUp?'80%':'250'"
                        class="elevation-5 mx-auto"
                        frameborder="0"
                        style="border:0;"
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                    ></iframe>
                </v-col>
            </v-row>

        </v-col>

        <!-- form -->
        <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="1">
            <v-form
                ref="contactForm"
                v-model="contactForm.valid"
                class="mt-3"
                lazy-validation
            >

                <!-- last name -->
                <v-text-field
                    v-model="contactForm.inputs.lastName"
                    :rules="contactForm.rules.name"
                    label="Nom"
                    solo
                ></v-text-field>

                <!-- first name -->
                <v-text-field
                    v-model="contactForm.inputs.firstName"
                    :rules="contactForm.rules.name"
                    label="Prénom"
                    solo
                ></v-text-field>

                <!-- company -->
                <v-text-field
                    v-model="contactForm.inputs.company"
                    :rules="contactForm.rules.length"
                    label="Entreprise"
                    solo
                ></v-text-field>

                <!-- email -->
                <v-text-field
                    v-model="contactForm.inputs.email"
                    :rules="contactForm.rules.email"
                    required
                    solo
                >
                    <template slot="label">
                        Email<span style="color: #ED1847">*</span>
                    </template>
                </v-text-field>

                <!-- phone -->
                <v-text-field
                    v-model="contactForm.inputs.phone"
                    :rules="contactForm.rules.phone"
                    label="Téléphone"
                    solo
                ></v-text-field>

                <!-- subject -->
                <v-select
                    v-model="contactForm.inputs.subject"
                    :items="contactForm.subjects"
                    item-text="text"
                    item-value="code"
                    :rules="contactForm.rules.subject"
                    solo
                    required
                    @change="$emit('select-change')"
                >
                    <template slot="label">
                        Objet<span style="color: #ED1847">*</span>
                    </template>
                </v-select>

                <!-- message -->
                <v-textarea
                    v-model="contactForm.inputs.message"
                    :rules="contactForm.rules.message"
                    solo
                    required
                >
                    <template slot="label">
                        Message<span style="color: #ED1847">*</span>
                    </template>
                </v-textarea>

                <!-- agreement -->
                <v-checkbox
                    v-model="contactForm.inputs.agreement"
                    :rules="contactForm.rules.agreement"
                    required
                    class="mt-0"
                >
                    <template slot="label">
                        <span class="text-caption text-justify">
                            <span class="align-self-start" style="color: #ED1847">*</span>En soumettant ce formulaire, j'accepte que mes données personnelles puissent être utilisées par la SAS Shine Group ou l'une de ses filiales dans le cadre d'opérations de prospection commerciale.
                        </span>
                    </template>
                </v-checkbox>

                <!--                recaptcha notification-->
                <p class="text-caption mb-0 text-center text-light">This site is protected by reCAPTCHA and the Google <a class="font-italic" href="https://policies.google.com/privacy">Privacy Policy</a> and <a class="font-italic" href="https://policies.google.com/terms">Terms of Service</a> apply.</p>

                <!-- button -->
                <v-row>
                    <v-col cols="12" md="7" offset-md="5" lg="6" offset-lg="6" class="d-flex justify-center justify-md-end">
                        <v-btn
                            min-width="250"
                            height="40"
                            color="shineGreen"
                            class="text-button font-weight-bold white--text"
                            rounded
                            :disabled="!contactForm.inputs.agreement"
                            @click="onContactFormSubmit"
                        >
                            envoyer
                        </v-btn>
                    </v-col>
                </v-row>

            </v-form>
        </v-col>

    </v-row>

    <!-- confirm feedback -->
    <v-dialog
        max-width="350"
        v-model="contactForm.feedback.mailSent"
        transition="scale-transition"
    >
        <v-alert
            class="text-center mb-0"
            color="shineGreen"
            type="success"
        >
            Votre message a bien été envoyé<br>
            <span class="text-caption">Nous vous répondrons dans les plus brefs délais</span>
        </v-alert>
    </v-dialog>

    <!-- error feedback -->
    <v-dialog
        max-width="450"
        v-model="contactForm.feedback.mailError"
        transition="scale-transition"
    >
        <v-alert
            class="text-center mb-0"
            color="shineRed"
            type="error"
        >
            Désolé, votre message n'a pas été envoyé<br>
            <span class="text-caption">Veuillez s'il vous plaît utiliser un autre moyen pour nous contacter</span>
        </v-alert>
    </v-dialog>

</v-container>
</section>
</template>

<script>
import { pathToAsset } from '../mixins/pathToAsset.js'
import { intersectionHandler } from '../mixins/intersectionHandler.js'

export default {
    name: 'Contact',

    mixins: [
      intersectionHandler,
      pathToAsset
    ],

    props: {
        p_bDevisSubject: Boolean
    },
    watch: {
        p_bDevisSubject(newValue) {
            if (newValue) {
                this.contactForm.inputs.subject = 'Demande de devis'
            }
        }
    },


    data: function(){ return {
        id: "contact",

        title: "Nous contacter",

        testimony: {
            text: "Shine Medical vous accompagne dans le développement de vos logiciels applicatifs médicaux. Nous avons conscience des spécificités de vos métiers et des contraintes règlementaires liées à votre domaine d’activité. C’est pourquoi nous vous proposons un accompagnement personnalisé couvrant toutes les phases de développement, de la conception à la mise sur le marché. Contactez-nous et ensemble, construisons vos logiciels médicaux de demain.",
            author: {
                name: "Sylvain GROSDEMOUGE",
                function: "Président de Shine Group",
                photo: {
                    src: "sylvain-grosdemouge.jpg",
                    alt: "portrait photographique de Sylvain Grosdemouge"
                }
            }
        },

        contactForm: {
            subjects: [
                {
                    code: 'Prise de rendez-vous',
                    text: 'Prise de rendez-vous'
                },
                {
                    code: 'Demande de devis',
                    text: 'Demande de devis'
                },
                {
                    code: 'Candidature',
                    text: 'Candidature'
                },
                {
                    code: 'Autre demande',
                    text: 'Autre demande'
                }
            ],

            inputs: {
                structure_id: 3,
                email: "",
                lastName: "",
                firstName: "",
                company: "",
                phone: "",
                subject: "",
                message: "",
                agreement: false,
            },

            valid: false,

            rules: {
                name: [
                    v=> /^([a-z ùéèàüäëïöûâêîô]+(-[a-z ùéèàüäëïöûâêîô]+)?)?$/i.test(v) || "Veuillez entrer un nom valide",
                    v=> (!v || v.length<=45) || 'Le nom est limité à 45 caractères',
                ],

                length: [
                    v=> (!v || v.length<=45) || 'Le nom est limité à 45 caractères',
                ],

                email: [
                    v=> !!v || 'Veuillez entrer une adresse email',
                    v=>  /^([a-z0-9_%+\-"]+\.)*[a-z0-9_%+\-"]+@([a-z0-9]+[.-]?)*[a-z0-9]+\.[a-z]{2,}$/.test(v) || 'Veuillez entrer une adresse email valide'
                ],

                phone: [
                    v=> (!v || /^([+]?([(]?[0-9]{1,4}[)]?){0,2}([-\s./]?[0-9]+)+)?$/.test(v)) || 'Veuillez entrer un numéro de téléphone valide',
                    v=> (!v || v.length<=25) || 'Le numéro de téléphone est trop long',
                ],

                subject: [
                    v=> !!v || 'Veuillez séléctionner un objet'
                ],

                message: [
                    v=> !!v || 'Veuillez écrire un message',
                    v=> (v && v.length<=2000) || 'Le message est limité à 2000 caractères',
                ],

                agreement: [
                    v=> !!v
                ]

            },

            feedback: {
                mailSent: false,
                mailError: false
            }

        },

    }},

    methods: {
        onContactFormSubmit(){
            // check if form is valid
            if ( this.$refs.contactForm.validate()){
                // get recaptcha token
                window.grecaptcha.ready( () => {
                    window.grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY, {action: 'submit'})
                    .then(recaptchaToken => {
                        // submit form with recaptcha token to verify
                        this.axios.post( 'contact/'+recaptchaToken , this.contactForm.inputs )
                        .then(response=>{
                            if ( response.statusText == "OK" ){
                                this.contactForm.feedback.mailSent = true
                            }else{
                                this.contactForm.feedback.mailError = true
                            }
                        })
                        .catch(()=>{
                            this.contactForm.feedback.mailError = true
                        })
                    })
                })
            }
        }
    }

}
</script>

<style>
.v-input--checkbox .v-input__slot{
    align-items: flex-start !important;
}

.grecaptcha-badge {
    visibility: hidden;
}

.text-light{
    opacity: 0.6;
}
</style>
