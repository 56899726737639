<template>
<v-row class="my-3">

    <v-col
        cols="12" md="6" xl="5" :offset-xl="reverse?1:0"
        order="1"
    >

        <!-- text with animation on md+ screen -->
        <v-lazy
            class="d-none d-md-block"
            :options="{
                threshold: 0,
                rootMargin: '99999px 0px -225px 0px'
            }"
            height="450"
            :transition="reverse?'scroll-x-transition':'scroll-x-reverse-transition'"
        >
            <div class="d-flex flex-column justify-center" style="height: 100%">

                <!-- title -->
                <v-row class="flex-column flex-grow-0">
                    <v-col v-if="activity.title" cols="12" class="d-flex justify-center align-center">
                        <v-icon large :color="activity.color" left>mdi-chevron-left</v-icon>
                        <h3 class="text-h4 text-center">{{ activity.title }}</h3>
                        <v-icon large :color="activity.color" right>mdi-chevron-right</v-icon>
                    </v-col>
                </v-row>

                <!-- text -->
                <v-row class="flex-grow-0">
                    <v-col cols="12">
                        <p class="text-h6 font-weight-regular mb-0 text-justify" v-html="activity.htmlDescription"></p>
                    </v-col>
                </v-row>

                <v-row v-if="activity.button || activity.logo" >

                    <!-- button -->
                    <v-col cols="12" class="d-flex align-start justify-center">
                        <v-btn
                            v-if="activity.button"
                            :href="activity.button.href"
                            target="_blank"
                            :color="activity.color"
                            width="250"
                            height="40"
                            class="text-button font-weight-bold white--text mt-10"
                            rounded
                            @click.prevent="devisClick"
                        >
                            {{ activity.button.text }}
                        </v-btn>
                    </v-col>

                    <!-- logo -->
                    <v-col
                        v-if="activity.logo" md="5" class="d-flex align-center" :order="reverse">
                        <v-img
                            :src="pathToAsset(activity.logo.src)"
                            :alt="activity.logo.alt"
                            height="32"
                            contain
                            eager
                        ></v-img>
                    </v-col>

                </v-row>
            </div>
        </v-lazy>

        <!-- text without animation on sm- screen -->
        <div class="d-block d-md-none">

            <!-- title -->
            <v-row v-if="activity.title">
                <v-col cols="12" class="d-flex justify-center">
                    <v-icon left large :color="activity.color">mdi-chevron-left</v-icon>
                    <h3 class="text-h4 text-center">{{ activity.title }}</h3>
                    <v-icon right large :color="activity.color">mdi-chevron-right</v-icon>
                </v-col>
            </v-row>

            <!-- description -->
            <v-row>
                <v-col cols="12" sm="8" offset-sm="2">
                    <p class="text-body-1 mb-0 text-justify" v-html="activity.htmlDescription"></p>
                </v-col>
            </v-row>

            <v-row
                v-if="activity.logo || activity.button"
                class="flex-grow-0"
            >

                <!-- button -->
                <v-col cols="12" sm="7" class="d-flex justify-center justify-sm-end">
                    <v-btn
                        v-if="activity.button"
                        :href="activity.button.href"
                        target="_blank"
                        :color="activity.color"
                        width="250"
                        height="40"
                        class="text-button font-weight-bold white--text"
                        rounded
                        @click.prevent="devisClick"
                    >
                        {{ activity.button.text }}
                    </v-btn>
                </v-col>

                <!-- logo -->
                <v-col
                    v-if="activity.logo" cols="12" sm="5">
                    <v-img
                        :src="pathToAsset(activity.logo.src)"
                        :alt="activity.logo.alt"
                        height="32"
                        contain
                        eager
                    ></v-img>
                </v-col>

            </v-row>
        </div>

    </v-col>

    <!-- image on md+ screen -->
    <v-col class="d-none d-md-block" cols="6" xl="5" :offset-xl="reverse?0:1" :order="reverse">
        <v-lazy
            :options="{
                threshold: 0,
                rootMargin: '9999px 0px -225px 0px'
            }"
            :transition="reverse?'scroll-x-reverse-transition':'scroll-x-transition'"
            height="450"
        >

            <v-img
                v-if="activity.images.length == 1"
                :src="pathToAsset(activity.images[0].src)"
                :alt="activity.images[0].alt"
                height="450"
                contain
                eager
            ></v-img>

            <!-- images carousel -->
            <v-carousel
                v-else
                height="450"
                cycle
                interval="10000"
                :show-arrows="false"
                delimiter-icon="mdi-hexagon-slice-6"
            >

                <v-carousel-item
                    v-for="(image,index) in activity.images"
                    :key="index"
                    :src="pathToAsset(image.src)"
                    :alt="image.alt"
                >
                </v-carousel-item>
            </v-carousel>

        </v-lazy>
    </v-col>

</v-row>
</template>

<script>
import { pathToAsset } from '../mixins/pathToAsset.js'

export default {
    name: 'Activity',

    mixins: [
      pathToAsset
    ],

    props: {
        activity: Object,
        reverse: Number
    },

    methods: {
        devisClick() {
            this.$vuetify.goTo('#contact', { duration: 500 })
            this.$emit('devis-click')
        }
    }

}
</script>

<style scoped>
.text-height{
    line-height: 140%;
}
</style>
