<template>
<div
    class="d-flex flex-column"
>
    <!-- quote -->
    <v-icon
        size="100"
        color="shineRed"
        class="mt-n7"
    >
        mdi-format-quote-close
    </v-icon>

    <!-- text -->
    <p class="text-body-1 white--text text-justify px-sm-16 mb-1">
        {{ testimony.text }}
    </p>
    <p class="text-h6 white--text text-right px-1 px-sm-16 mb-0">
        {{ testimony.author.name }}
    </p>
    <p class="text-body-1 font-italic white--text text-right px-1 px-sm-16 mb-0">
        {{ testimony.author.function }}
    </p>
</div>
</template>

<script>
export default {
    name: 'Testimony',

    props: {
        testimony: Object
    }
}
</script>
