<template>
<a :href="partner.href" target="_blank">
    <v-card
        class="d-flex flex-column mx-15 animated-card"
        :class="{
            'slide':  running && partner.visible
        }"
        width="200" height="200"
        :style="{transform: 'translateX('+(XPosition)+'px)' , 'animation-duration': animationDuration}"
        v-intersect="{
            handler: offScreen,
            options: {
                threshold: 0,
                rootMargin: '99999px 260px'
            }
        }"
    >
        <v-img
            :src="pathToAsset(partner.logo.src)"
            :alt="partner.logo.alt"
            contain
            eager
        ></v-img>
    </v-card>
</a>
</template>

<script>
import { pathToAsset } from '../mixins/pathToAsset.js'

export default {
    name: 'Partner',

    mixins: [
      pathToAsset
    ],

    props: {
        partner: Object,
        index: Number,
        running: Boolean
    },

    data: function(){
        return{
            XPosition: 0,
            animationDuration: '50s'
        }
    },

    methods: {
        offScreen( entries ){
            if (!entries[0].isIntersecting){
                this.XPosition = 0
                this.animationDuration = '50s'
                this.$emit( 'offScreen' , {index: this.index} )
            }
        },

        onVisibilityChange(){
            if (document.hidden) {
                this.XPosition = (new DOMMatrixReadOnly(window.getComputedStyle(this.$el.firstChild).transform).m41)
                this.animationDuration = (50*4000/(4000-this.XPosition)) + 's'
            }
        }
    },

    mounted(){
        document.addEventListener( 'visibilitychange' , this.onVisibilityChange )
    }

}
</script>

<style scoped>
@keyframes slide {
    to{
        transform: translateX(-4000px);
    }
}

.slide{
    animation-name: slide;
}

.animated-card{
    position: absolute;
    right: -260px;
    animation-timing-function: linear;
    animation-fill-mode: both;
}
</style>
