<template>
    <aside>
        <v-app-bar dark app short>

            <v-icon @click="$emit('close-legal-dialog')" left>mdi-close</v-icon>
            <v-toolbar-title class="text-uppercase text-subtitle-2 ml-2" style="letter-spacing: 0.0892857143em !important">Mentions Légales</v-toolbar-title>

        </v-app-bar>

        <v-main class="py-10">
            <v-container>
                <v-row>
                    <v-col>
                        <section>

                            <h2 class="text-center text-sm-left  text-h5 text-sm-h4 my-4">Mentions légales de Shine Medical</h2>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Directeur de publication</h3>

                                <p>
                                    Sylvain GROSDEMOUGE,<br>
                                    Président de la société Shine Group.
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Animation éditoriale, gestion et mise à jour</h3>

                                <p>Service communication</p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Photographies</h3>

                                <p>Service communication</p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Maintenance technique et hébergement</h3>

                                <p>
                                    Shine Medical (&laquo; la Société &raquo;)<br>
                                    1 Rue du Pré Brenot<br>
                                    25870 Châtillon-Le-Duc
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Règlement Général sur la Protection des Données (RGPD)</h3>

                                <p>
                                    Les données recueillies sur le site résultent de la communication volontaire de données à caractère
                                    personnel par les visiteurs et usagers du service. A cet égard les mineurs de moins de 15 ans doivent être
                                    autorisés par le titulaire de l’autorité parentale ou son représentant légal pour s’y connecter. Les données
                                    ainsi recueillies ne servent qu’à transmettre les éléments d’informations liés aux demandes, aux missions
                                    effectuées et aux prestations réalisées par la société.
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Dispositions légales</h3>

                                <p>
                                    Les bases légales correspondant aux traitements de ces données sont les articles 6.1.a et 6.1.e du RGPD
                                    (règlement européen en matière de protection des données personnelles) et article 7 et 7-3° de La loi
                                    informatique et libertés (consentement et traitement nécessaire à l’exécution d’une mission d’intérêt
                                    public ou relevant de l’exercice de l’autorité publique dont est investi le responsable du traitement), ainsi
                                    que les articles 6.1.c et 7-4° du RGPD et articles 7-1° et 7-4° de la Loi informatique et libertés (respect
                                    d’une obligation légale et exécution d’un contrat ou de mesure pré contractuelle).
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Traitement des données personnelles</h3>

                                <p>
                                    Les données personnelles recueillies en provenance des formulaires et des courriels présents sur ce site
                                    servent uniquement à apporter une réponse aux questions ou à traiter les demandes des internautes usagers. Elles vous sont demandées dans un cadre légal défini ou une finalité spécifique. Dans ce cas votre
                                    consentement peut être nécessaire au traitement de votre demande. Votre consentement vous est alors
                                    demandé explicitement et vous avez la faculté de le retirer à tout moment directement à partir de notre
                                    site. Ces données ne seront pas conservées après le traitement de la demande et ne serviront à aucun
                                    autre usage.
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Données personnelles traitées</h3>

                                <p>
                                    De manière générale, les données collectées et traitées après connexion au site web sont les suivantes :
                                <ul>
                                    <li>Nom de famille ;</li>
                                    <li>Prénom ;</li>
                                    <li>Entreprise ;</li>
                                    <li>Coordonnées téléphoniques ;</li>
                                    <li>Adresse électronique ;</li>
                                    <li>toutes informations requises par la loi ;</li>
                                </ul>
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Sécurité des traitements</h3>

                                <p>
                                    Dans le cadre de leur traitement par la Société, vos données sont traitées selon des protocoles sécurisés
                                    et permettant de gérer les demandes reçues dans ses applications informatiques. Seules les informations
                                    utiles et nécessaires au traitement vous sont demandées. Les informations enregistrées sont réservées à
                                    l’usage exclusif de la Société. Sauf stipulation expresse elles ne peuvent faire l’objet d’une autre utilisation
                                    que celle pour laquelle elles ont été collectées (respect de la finalité).
                                    Ces informations sont alors conservées conformément à la règlementation en vigueur et pendant une
                                    durée justifiée par la finalité de leur traitement.
                                    Les données recueillies par la Société sur les formulaires ne font l’objet d’aucune cession à des tiers, sauf
                                    stipulation contraire directement mentionnée lors de la collecte en raison de finalités de traitement
                                    particulières.
                                    Aucun profilage ne sera réalisé et plus généralement aucune décision automatisée ne sera prise sur la
                                    base des données collectées.
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Droits d’accès aux données personnelles</h3>

                                <p>
                                    Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée et au Règlement
                                    européen n°2016/679/UE, vous disposez du droit de demander au responsable du traitement l’accès à
                                    vos données personnelles, vous pouvez demander leur rectification, leur effacement ou une limitation
                                    du traitement vous concernant dans certains cas précis. Vous pouvez également, pour des motifs
                                    légitimes, vous opposer au traitement des données à caractère personnel vous concernant, sauf lorsque
                                    le traitement répond à une obligation légale ou lorsque ce droit d’opposition a été écarté par une
                                    disposition expresse de l’acte autorisant le traitement.
                                    Vous pouvez également demander la portabilité de vos données, pour des motifs légitimes et dans les
                                    conditions fixées par la loi.
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Cookies utilisés sur notre site</h3>

                                <p>
                                    Les cookies sont des fichiers de données stockés sur le disque-dur de votre ordinateur, tablette ou mobile
                                    qui facilitent la navigation entre les pages du site et permettent un accès plus rapide aux informations
                                    recherchées lorsque vous visitez régulièrement le site. La plupart des navigateurs web accepte les cookies
                                    et vous pouvez cependant vous opposer à l’enregistrement de cookies en modifiant les paramètres de
                                    votre navigateur. Toutefois, si vous décidez de désactiver les cookies de notre site, certaines
                                    fonctionnalités pourraient être rendues inaccessibles ou diminuées.
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Engagement de conformité</h3>

                                <p>
                                    La Société adhère pleinement aux dispositions du Règlement Européen pour la Protection de vos
                                    Données personnelles. Vous pouvez, sous réserve de la production d’un justificatif d’identité valide,
                                    exercer vos droits en contactant le Délégué à la Protection des Données mutualisé à
                                    l’adresse dpo@shine-medical.com ou par courrier à :
                                </p>

                                <p>
                                    Shine Medical<br>
                                    Délégué à la Protection des Données<br>
                                    1 Rue du Pré Brenot<br>
                                    25870 Châtillon-Le-Duc
                                </p>

                                <p>
                                    Le responsable du traitement est la société Shine Medical, 1 Rue du Pré Brenot, 25870 Châtillon-Le-Duc,
                                    représentée Monsieur Sylvain Grosdemouge. Le cas échéant, le Responsable de traitement se réserve le
                                    droit de réclamer tout document permettant de contrôler l’identité du demandeur.
                                    Le Responsable de Traitement vous répond dans les meilleurs délais et dans un délai maximum d’un
                                    mois à compter de la réception de votre demande. Ce délai peut être prolongé de deux mois à condition
                                    que les motifs de ce report vous soient notifiés sous le délai d’un mois à réception de votre requête. Le
                                    cas échéant, si le Responsable de Traitement ne peut satisfaire à votre demande ou s’il ne donne pas
                                    suite, il vous informe également dans les mêmes conditions.
                                    Pour toute information complémentaire ou réclamation, vous pouvez contacter la Commission
                                    Nationale de l’Informatique et des Libertés (plus d’informations sur www.cnil.fr).
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Dispositions légales</h3>

                                <p>
                                    Les divers éléments du site web (la forme, la mise en page, le fonds, la structure …) sont protégés par le
                                    droit des dessins et modèles, le droit d’auteur, le droit des marques ainsi que le droit à l’image et ils ne
                                    peuvent être copiés ou imités en tout ou partie sauf autorisation expresse de la Société.
                                    Toute personne ne respectant pas les dispositions légales applicables se rend coupable du délit de
                                    contrefaçon et est passible des sanctions pénales prévues par la loi.
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Droits d’auteurs</h3>

                                <p>
                                    Le présent site constitue une œuvre dont la Société est l’auteur au sens des articles L. 111.1 et suivants
                                    du Code de la propriété intellectuelle.
                                    Les photographies, textes, logos, pictogrammes, ainsi que toutes œuvres intégrées dans le site sont la
                                    propriété de La Société ou de tiers ayant autorisé la Société à les utiliser.
                                    Les reproductions, les transmissions, les modifications, les réutilisations, sur un support papier ou
                                    informatique, du dit site et des œuvres qui y sont reproduites ne sont autorisées que pour un usage
                                    personnel et privé conforme aux dispositions de l’article L 122-5 du Code de la Propriété Intellectuelle.
                                    Ces reproductions devront ainsi notamment indiquer clairement la source et l’auteur du site et/ou de
                                    ces œuvres multimédias.
                                    En aucun cas ces reproductions ne sauraient porter préjudice aux droits des tiers.
                                    Les reproductions, les transmissions, les modifications, les réutilisations à des fins publicitaires,
                                    commerciales ou d’information, de tout ou partie du site, sont totalement interdites.
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Droit des bases de données</h3>

                                <p>
                                    Les bases de données sont protégées par la loi du 1er juillet 1998 et le régime français du droit d’auteur.
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Établissements de liens vers le site de la Société</h3>

                                <p>
                                    La Société autorisent la mise en place d’un lien hypertexte vers leurs sites pour tous les sites Internet, à
                                    l’exclusion de ceux diffusant des informations à caractère polémique, pornographique, xénophobe ou
                                    pouvant, dans une plus large mesure porter atteinte à la sensibilité du plus grand nombre.
                                    Le lien doit aboutir à la page d’accueil de la Société et doit apparaître dans une nouvelle fenêtre. Les pages
                                    du site de la Société ne doivent en aucun cas être intégrées à l’intérieur des pages d’un autre site (iframe).
                                    Dans tous les cas d’espèce, la Société se réservent le droit de demander la suppression d’un lien si elle
                                    estime que le site cible ne respecte pas les règles ainsi définies.
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Liens vers des sites tiers depuis le site de la Société</h3>

                                <p>
                                    Les liens hypertextes mis en œuvre au sein du site en direction d’autres sites et/ou de pages personnelles
                                    et d’une manière générale vers toutes ressources existantes sur internet ne sauraient engager la responsabilité de la Société quant aux liens qu’ils contiennent ou aux changements ou mises à jour qui
                                    leur sont apportés.
                                </p>
                            </section>

                            <section>
                                <h3 class="text-center text-sm-left text-h6 text-sm-h5 my-2">Mise en garde générale</h3>

                                <p>
                                    Nos services mettent tout en œuvre pour offrir aux visiteurs de ce site web des informations fiables et
                                    vérifiées. Cependant, malgré tous les soins apportés, le site peut comporter des inexactitudes, des défauts
                                    de mise à jour ou des erreurs
                                </p>
                            </section>
                        </section>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </aside>
</template>

<script>
export default {
    name: 'Legal',

    data: function(){ return {

    }}

}
</script>

<style scoped>
.v-main{
    background-color: #FAFAFA;
}
</style>
