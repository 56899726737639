<template>
<section
    :id="id"
    style="position: relative"
    class="pt-3"
    v-intersect="{
        handler: intersectionHandler,
        options: {
            threshold: 0,
            rootMargin: '-49% 0px -49% 0px'
        }
    }"
>

<v-container>

    <!-- title -->
    <v-row>
        <v-col>
            <h2 class="text-h4 text-sm-h3 font-weight-medium text-center text-md-left">{{ title }}</h2>
        </v-col>
    </v-row>

    <v-row>

        <!-- last news -->
        <v-col cols="12" md="5" lg="6" xl="5" offset-xl="1" >

            <!-- block md+ -->
            <v-lazy
                :options="{
                    threshold: 0,
                    rootMargin: '99999px 0px -120px 0px'
                }"
                min-height="240"
                transition="scroll-x-transition"
                class="d-none d-md-block"
            >
                <div>
                    <!-- image md+ -->
                    <v-row>
                        <v-col>
                            <a :href="news[0].link.href" target="_blank">
                                <v-img
                                    :src="pathToAsset(news[0].image.src)" :alt="news[0].image.alt"
                                    height="240"
                                    class="elevation-5"
                                    eager
                                ></v-img>
                            </a>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <!-- text md+ -->
                            <h3 class="text-h6 mb-3 text-height">{{ news[0].title }}</h3>
                            <p class="text-body-2 mb-3 text-justify">{{ news[0].abstract }}</p>
                            <p
                                v-if="news[0].image.copyright"
                                class="text-caption mb-0"
                            >
                                Crédit photo: {{ news[0].image.copyright }}
                            </p>
                            <p class="text-body-2 font-weight-medium mb-0" :style="{color: news[0].link.color}">
                                <a :href="news[0].link.href" target="_blank">
                                    <v-icon class="mb-1 mr-1" size="18" :color="news[0].link.color">mdi-open-in-new</v-icon>
                                    L'article sur {{news[0].link.source}}
                                </a>
                            </p>
                        </v-col>
                    </v-row>
                </div>
            </v-lazy>

            <!-- block sm- -->
            <div class="d-block d-md-none">

                <!-- image sm- -->
                <v-row>
                    <v-col>
                        <v-lazy
                            :options="{
                                threshold: 0,
                                rootMargin: '9999px 0px -120px 0px'
                            }"
                            min-height="240"
                            transition="scroll-x-transition"
                        >
                            <a :href="news[0].link.href" target="_blank">
                                <v-img
                                    :src="pathToAsset(news[0].image.src)" :alt="news[0].image.alt"
                                    height="240"
                                    class="elevation-5"
                                    eager
                                ></v-img>
                            </a>
                        </v-lazy>
                    </v-col>
                </v-row>

                <!-- text sm- -->
                <h3 class="text-h6 my-3 text-height">{{ news[0].title }}</h3>
                <p class="text-body-2 mb-3 text-justify">{{ news[0].abstract }}</p>
                <p
                    v-if="news[0].image.copyright"
                    class="text-caption text-right mb-0"
                >
                    Crédit photo: {{ news[0].image.copyright }}
                </p>
                <p class="text-body-2 text-right font-weight-medium mb-0" :style="{color: news[0].link.color}">
                    <a :href="news[0].link.href" target="_blank">
                        <v-icon class="mb-1 mr-1" size="18" :color="news[0].link.color">mdi-open-in-new</v-icon>
                        L'article sur {{news[0].link.source}}
                    </a>
                </p>

            </div>

        </v-col>

        <!-- secondary news -->
        <v-col cols="12" md="7" lg="6" xl="5">

            <!-- news 2 -->
            <SideNews :news="news[1]"/>

            <!-- news 3 -->
             <SideNews :news="news[2]"/>

        </v-col>
    </v-row>

</v-container>
</section>
</template>

<script>
import { pathToAsset } from '../mixins/pathToAsset.js'
import { intersectionHandler } from '../mixins/intersectionHandler.js'
import SideNews from './SideNews'

export default {
    name: 'News',

    components: {
        SideNews
    },

    mixins: [
      pathToAsset,
      intersectionHandler
    ],

    data: function(){ return {
        id: "news",

        title: "Nos actualités",

        news: [
            {
                title: "Cancer du sein : silhouette 3D pour repositionner les patientes",
                abstract: "Repositionner précisément et facilement les patientes traitées pour des cancers du sein d’une séance de radiothérapie à l’autre est essentiel pour la qualité des soins. Pour répondre à cet enjeu, un consortium franc-comtois développe un outil de réalité virtuelle.",
                image: {
                    src: "news_cancer.jpg",
                    alt: "photo d'une patiente alongée sous un scanner et d'un médecin à ses côtés qui porte un masque de réalité virtuelle",
                    copyright: "Franck LALLEMAND"
                },
                link: {
                    href: "https://www.estrepublicain.fr/magazine-sante/2020/11/02/cancer-du-sein-une-silhouette-3d-pour-repositionner-au-mieux-les-patientes",
                    source: "L'Est Républicain",
                    color: "#ED1847"
                }
            },
            {
                title: "EasyPédia : améliorer la prise en charge de l'enfant en détresse vitale",
                abstract: "En 2018, Pauline Ecoffet, puéricultrice en réanimation infantile, décide de participer au Hacking Health de Besançon pour relever un défi...",
                image: {
                    src: "news_easypedia.jpg",
                    alt: "photo d'un nouveau né en auscultation",
                    copyright: "CHRU Besançon"
                },
                link: {
                    href: "https://www.chu-besancon.fr/le-chru/actualites-du-chru/actualite/easypedia-un-projet-innovant-qui-pourrait-ameliorer-la-prise-en-charge-de-lenfant-en-detresse-vit.html",
                    source: "CHU Besançon",
                    color: "#ED1847"
                }
            },
            {
                title: "Hacking Health : les projets innovants prennent vie dans le monde de la santé",
                abstract: "Avant la 3e édition du Hacking Health à Besançon, les organisateurs et partenaires font un point sur les avancées.",
                image: {
                    src: "news_hacking.jpg",
                    alt: "photos de personnes discutant autour d'une table",
                    copyright: "Alexane Alfaro"
                },
                link: {
                    href: "https://www.macommune.info/hacking-health-a-besancon-quand-les-projets-innovants-prennent-vie-dans-le-monde-de-la-sante/",
                    source: "maCommune.info",
                    color: "#ED1847"
                }
            }
        ]
    }}

}
</script>

<style scoped>
.text-height{
    line-height: 130%;
}
</style>
