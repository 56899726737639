<template>
<section
    :id="id"
    class="d-flex align-center"
    :style="backgroundImageStyle"
    v-intersect="{
        handler: intersectionHandler,
        options: {
            threshold: 0,
            rootMargin: '-49% 0px -49% 0px'
        }
    }"
>
<v-container>

    <!-- big logo -->
    <v-row>
        <v-col>
            <v-img
                :src="pathToAsset(largeLogo.src)"
                :alt="largeLogo.alt"
                :style="{width: logoWidth+'px'}"
                class="mx-auto"
                eager
            ></v-img>
        </v-col>
    </v-row>

    <!-- slogan -->
    <!-- one line on md+, two lines on sm- -->
    <v-row :style="$vuetify.breakpoint.xsOnly?{'height': '112px'}:$vuetify.breakpoint.smOnly?{'height': '128px'}:''">
        <v-col>
            <h1
                class="text-h5 text-sm-h4 text-lg-h3 mx-auto my-3 my-lg-5"
                :style="{width: sloganWidth+'px'}"
            >
                <!-- characters -->
                <span v-for="(character,index) in slogan.visibleCharacters" :key="index">{{ character }}</span>
                <!-- cursor -->
                <span :class="{'blink': slogan.visibleCharacters.length==0 || slogan.visibleCharacters.length==sloganCharacters.length}">|</span>
            </h1>
        </v-col>
    </v-row>

</v-container>
</section>
</template>

<script>
import { pathToAsset } from '../mixins/pathToAsset.js'
import { intersectionHandler } from '../mixins/intersectionHandler.js'

export default {
    name: 'Hero',

    mixins: [
        pathToAsset,
        intersectionHandler
    ],

    data: function(){ return {
        id: "home",

        background: "hero.jpg",

        largeLogo: {
            src: "logo_shine_medical_couleur_blanc.png",
            alt: "logo de Shine Medical"
        },

        slogan: {
            text: "Développement logiciel adapté au secteur médical",
            width: {
                xs: 340,
                sm: 500,
                md: 782,
                lg: 1090
            },
            visibleCharacters: [],
            currentAnimationInterval: null
        }

    }},

    computed: {
        backgroundImageStyle(){
            return { backgroundImage: `url(${this.pathToAsset(this.background)})` }
        },

        sloganWidth(){
            return this.$vuetify.breakpoint.xsOnly?this.slogan.width.xs:this.$vuetify.breakpoint.smOnly?this.slogan.width.sm:this.$vuetify.breakpoint.mdOnly?this.slogan.width.md:this.slogan.width.lg
        },

        logoWidth(){
            return this.$vuetify.breakpoint.mdAndUp?this.sloganWidth*1/2:this.sloganWidth*2/3
        },

        sloganCharacters(){
            return this.slogan.text.split("")
        }
    },

    methods:{
        triggerSloganShowAnimation(){
            // show next character every 0.1s
            this.slogan.currentAnimationInterval = setInterval(this.showSloganNextCharacter,60)
        },

        showSloganNextCharacter(){
            // check if show animation is finished
            if( this.slogan.visibleCharacters.length == this.sloganCharacters.length ){
                // stop show animation and start hide animation in 4s
                clearInterval(this.slogan.currentAnimationInterval)
                setTimeout(this.triggerSloganHideAnimation,4000)
            }else{
                // show next character
                this.slogan.visibleCharacters.push(this.sloganCharacters[this.slogan.visibleCharacters.length])
            }
        },

        triggerSloganHideAnimation(){
            // hide character every 0.1s
            this.slogan.currentAnimationInterval = setInterval(this.hideSloganLastCharacter,25)
        },

        hideSloganLastCharacter(){
            // check if hide animation is finished
            if(this.slogan.visibleCharacters.length == 0){
                // stop hide animation and start show animation in 2s
                clearInterval(this.slogan.currentAnimationInterval)
                setTimeout(this.triggerSloganShowAnimation,2000)
            }else{
                // hide last character
                this.slogan.visibleCharacters.pop()
            }
        }
    },

    mounted(){
        this.triggerSloganShowAnimation()
    }

}
</script>

<style scoped>
section{
    background-size: cover;
    background-color: rgba(0,0,0,0.6);
    background-blend-mode: overlay;
    min-height: calc(var(--vh, 1vh) * 100 - 56px);
    color: white;
}

.blink{
    animation: blink 1s infinite both;
}

@keyframes blink {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
</style>
