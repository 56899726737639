<template>
<v-app>
    <NavigationBar :activeNavItemId="activeSectionId"/>
    <v-main>
        <Hero @isEntering="activateNavItem"/>
        <Activities @isEntering="activateNavItem" @devis-click="setContactFormSubject"/>
<!--        <Projects @isEntering="activateNavItem"/>-->
        <News @isEntering="activateNavItem"/>
        <v-parallax :height="$vuetify.breakpoint.xsOnly?836:$vuetify.breakpoint.smOnly?702:654" :src="pathToAsset(parallax)">
            <Partners @isEntering="activateNavItem"/>
            <Testimonies />
        </v-parallax>
        <Contact @isEntering="activateNavItem" :p_b-devis-subject="d_bDevisClicked" @select-change="d_bDevisClicked=false"/>
        <Footer @open-legal-dialog="legalDialog=true"/>
        <!-- <Cookies @close-legal-dialog="legalDialog=false" @open-legal-dialog="legalDialog=true"/> -->
        <v-dialog v-model="legalDialog" fullscreen transition="dialog-bottom-transition" persistent>
            <Legal @close-legal-dialog="legalDialog=false"/>
        </v-dialog>
    </v-main>
</v-app>
</template>

<script>
import NavigationBar from '../components/NavigationBar'
import Hero from '../components/Hero'
// import Projects from '../components/Projects'
import Activities from '../components/Activities'
import News from '../components/News'
import Partners from '../components/Partners'
import Testimonies from '../components/Testimonies'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
// import Cookies from '../components/Cookies'
import Legal from '../components/Legal'
import { pathToAsset } from '../mixins/pathToAsset.js'

export default {
    name: 'Web',

    data: function(){ return {
        activeSectionId: "home",

        timeout: setTimeout(()=>{},1),

        parallax: 'parallax_1300.jpg',

        legalDialog: false,

        d_bDevisClicked: false
    }},

    components: {
        NavigationBar,
        Hero,
        // Projects,
        Activities,
        News,
        Partners,
        Testimonies,
        Contact,
        Footer,
        // Cookies,
        Legal
    },

    mixins: [
        pathToAsset
    ],

    methods: {
        activateNavItem(payload){
            //use timeout to prevent slider motion during automatic scroll
            clearTimeout(this.timeout)
            this.timeout = setTimeout(()=>{
                this.activeSectionId = payload.sectionId
            },200)
        },

        setVhCssVariable(){
            let vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vh}px`)
        },

        setContactFormSubject() {
            this.d_bDevisClicked = true
        }
    },

    created(){
        this.setVhCssVariable()
        window.addEventListener('resize', this.setVhCssVariable)
    }
}
</script>
