<template>
<section
    :id="id"
>

    <v-container>

        <!-- website carrousel version -->
        <v-row >
            <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="pt-0">
                <v-carousel
                    :height="$vuetify.breakpoint.xsOnly?464:$vuetify.breakpoint.smOnly?320:272"
                    hide-delimiters
                    cycle
                    interval="20000"
                >
                    <v-carousel-item
                        v-for="(testimony,index) in testimonies"
                        :key="index"
                        transition="fade-transition"
                        reverse-transition="fade-transition"
                    >
                        <Testimony :testimony="testimony"/>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>

    </v-container>
</section>
</template>

<script>
import Testimony from './Testimony'

export default {
    name: 'Testimonies',

    components: {
        Testimony
    },

    data: function(){ return {
        id: "testimonies",

        testimonies: [
            {
                text: "Une équipe très disponible et à l'écoute. Un travail sérieux et de qualité. Niveau de qualité global : 5/5",
                author:{
                    name: "Pauline ECOFFET",
                    function: "Présidente @ Association SAUV Qui Peut"
                }
            },
            {
                text: "Nous avons été satisfait de la qualité du service et des échanges pour développer l’application « Buste 3d ». Réactivité et écoute des besoins ont été au cœur de la réussite de ce projet collaboratif…",
                author:{
                    name: "Benjamin GOIZET",
                    function: "Délégué général @ Innov'Health "
                }
            },
            {
                text: "Je vous confirme la grande qualité des interactions, la réactivité dans la recherche et la proposition de solutions face à nos problématiques. Échanges très intéressants également pour l’évolution vers Shine Medical.",
                author:{
                    name: "Thomas LIHOREAU",
                    function: "Ingénieur biomédical @ CHU Besançon"
                }
            },
            {
                text: "Sylvain Grosdemouge worked as a Software Engineer in my company, Atelier. Sylvain is not only a very talented engineer always focused on finding the best and most elegant solutions but also a great individual whose openness and cheerfulness make it very easy and enjoyable to work with. I highly recommend him.",
                author:{
                    name: "Marc MENSCHENFREUND",
                    function: "Senior Director & Corporate Business Development @ Intel Corporation"
                }
            },
            {
                text: "Sylvain Grosdemouge is very pragmatic: he had two kid games to deliver in a crazy short time so he decided to take our technology. With his strong knowledge in game technology, he could quickly get into that engine to adapt it to his needs. He is the lead you can rely on to get the job done on time.",
                author:{
                    name: "Edouard POUTOT",
                    function: "Computational Mechanics Programmer @ CAE Healthcare"
                }
            },
            {
                text: "Les entités de Shine Group, en participant à des projets collaboratifs nationaux et européens, renouvellent régulièrement leurs compétences en management de projets. Son savoir-faire basé sur une parfaite maîtrise des jeux logiciels et une grande créativité lui permettent de mettre à disposition de ses clients les compétences nécessaires pour leur permettre de se digitaliser rapidement et d’intégrer les dernières technologies.",
                author:{
                    name: "Anne-Marie VIEUX",
                    function: "Enterprise Europe Network @ CCI Bourgogne Franche-Comté"
                }
            },
            {
                text: "Shine Médical, Biotika et l'ISIFC collaborent de manière intelligente sur plusieurs projets R&D. Le premier est au service des patientes suivies en radiothérapie et le second a pour but de mieux prendre en charge des enfants en détresse vitale. Grace aux services informatiques de haute technologie, à la qualité et l'agilité de l'équipe de Shine, nous avons la possibilité d'imaginer les dispositifs médicaux connectés du futur et de les tester avec eux, tout en prenant en compte les exigences réglementaires, cliniques et normatives.",
                author:{
                    name: "Nadia BUTTERLIN",
                    function: "Chargée de mission de la valorisation de la recherche @ UFC"
                }
            }
        ]
    }}

}
</script>

<style>
#testimonies .v-window__prev{
    top: 8px;
    left: 23% !important;
}

#testimonies .v-window__next{
    top: 8px;
    right: 23% !important;
}

@media (max-width: 599px){
    #testimonies .v-window__prev{
        left: 0% !important;
    }

    #testimonies .v-window__next{
        right: 0% !important;
    }
}
</style>
