<template>
<section
    :id="id"
    v-intersect="{
        handler: intersectionHandler,
        options: {
            threshold: 0,
            rootMargin: '-49% 0px -49% 0px'
        }
    }"
>

    <v-container fluid>

        <!-- title -->
        <v-row>
            <v-col>
                <h2 class="text-h4 text-sm-h3 font-weight-medium white--text text-center my-3">{{ title }}</h2>
            </v-col>
        </v-row>

        <!-- partners -->
        <v-row>

            <!-- website animated version -->
            <v-col>
                <div style="height: 200px">
                    <Partner
                        v-for="(partner,index) in partners" :key="index"
                        :partner="partner"
                        :index="index"
                        :running="animation.run"
                        @offScreen="resetPartner"
                    />
                </div>
            </v-col>

        </v-row>

    </v-container>
</section>
</template>

<script>
import Partner from './Partner'
import { intersectionHandler } from '../mixins/intersectionHandler.js'

export default {
    name: 'Partners',

    components: {
        Partner
    },

    mixins: [
        intersectionHandler
    ],

    data: function(){ return {
        id: "partners",

        title: "Nos partenaires",

        partners: [
            {
                logo: {
                    src: "infc_anrt.jpg",
                    alt: "logo de l'Association Nationale Recherche Technologie",
                },
                href: "http://www.anrt.asso.fr/fr",
                visible: false
            },
            {
                logo: {
                    src: "infc_biotika.png",
                    alt: "logo de biotika",
                },
                href: "http://biotika.univ-fcomte.fr/",
                visible: false
            },
            {
                logo: {
                    src: "infc_bpifrance.jpg",
                    alt: "logo de bpifrance",
                },
                href: "https://www.bpifrance.fr/",
                visible: false
            },
            {
                logo: {
                    src: "infc_diabfcs.png",
                    alt: "logo de diab fc s",
                },
                href: "http://diabfcs.pitchy.buzz/",
                visible: false
            },
            {
                logo: {
                    src: "infc_hhbesancon.png",
                    alt: "logo du hacking health Besançon ",
                },
                href: "https://hacking-health.org/fr/besancon-fr/",
                visible: false
            },
            {
                logo: {
                    src: "infc_ih.png",
                    alt: "logo d'innov'health ",
                },
                href: "https://www.pmt-ih.com/en/home/",
                visible: false
            },
            {
                logo: {
                    src: "infc_isifc.png",
                    alt: "logo de l'ISIFC",
                },
                href: "http://isifc.univ-fcomte.fr/",
                visible: false
            },
            {
                logo: {
                    src: "infc_maincare.png",
                    alt: "logo de maincare solutions",
                },
                href: "https://www.maincare.com/",
                visible: false
            },
            {
                logo: {
                    src: "infc_mazars.jpg",
                    alt: "logo de mazars",
                },
                href: "https://www.mazars.com/",
                visible: false
            },
            {
                logo: {
                    src: "infc_mesr.jpg",
                    alt: "logo du ministère de l'enseignement supérieur, de la recherche et de l'innovation",
                },
                href: "https://www.enseignementsup-recherche.gouv.fr/",
                visible: false
            },
            {
                logo: {
                    src: "infc_pmt.png",
                    alt: "logo du pole des microtechniques",
                },
                href: "https://www.polemicrotechniques.fr/en/home/",
                visible: false
            },
            {
                logo: {
                    src: "infc_refc.jpg",
                    alt: "logo du Réseau Entreprendre",
                },
                href: "https://www.reseau-entreprendre.org/en/homepage/",
                visible: false
            },
            {
                logo: {
                    src: "infc_utbm.png",
                    alt: "logo de l'Université de Technologie de Belfort-Montbéliard",
                },
                href: "https://www.utbm.fr/",
                visible: false
            }
        ],

        animation: {
            run: false,
            interval: 3750,
            counter: 0,
            timeout: {
                current: null,
                start: null,
                remainingTime: 0
            }
        }

    }},

    methods:{
        showNextPartner(){
            let nextPartner = this.partners[this.animation.counter%this.partners.length]
            if (!nextPartner.visible){
                this.animation.counter++
                nextPartner.visible = true
            }
            //set fresh timeout
            this.animation.timeout.current = setTimeout(this.showNextPartner,this.animation.interval)
            this.animation.timeout.start = Date.now()
            this.animation.timeout.remainingTime = this.animation.interval
        },

        onVisibilityChange(){
            if (document.hidden) {
                //check if animation is running (not the case if tab is open as hidden)
                if (this.animation.run){
                    //pause animation and store remaining time
                    this.animation.run = false
                    clearTimeout(this.animation.timeout.current)
                    this.animation.timeout.remainingTime = this.animation.timeout.remainingTime-(Date.now()-this.animation.timeout.start)
                }
            } else {
                //play animation with remaining time
                this.animation.run = true
                this.animation.timeout.current = setTimeout(this.showNextPartner,this.animation.timeout.remainingTime)
                this.animation.timeout.start = Date.now()
            }
        },

        resetPartner( payload ){
            this.partners[payload.index].visible = false
        }
    },

    mounted(){
        document.addEventListener( 'visibilitychange' , this.onVisibilityChange )
        this.onVisibilityChange()
    }

}
</script>
