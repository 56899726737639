import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                shineOrange: '#F79434',
                shineBlue: '#00AEEF',
                shineGreen: '#A6CE39',
                shineRed: '#ED1847',
                shineBlack: '#272727',
                shineWhite: '#FAFAFA'
            },
        },
    }
});