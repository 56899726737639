<template>
<v-footer dark>
<v-container fluid>
<v-row>

    <!-- logo -->
    <v-col cols="12" sm="6" md="3" class="d-flex justify-center">
        <v-img :src="pathToAsset(logo.src)" :alt="logo.alt" contain width="100%" max-width="150" eager></v-img>
    </v-col>

    <!-- address -->
    <v-col cols="12" sm="6" md="3" class="d-flex justify-center justify-md-start align-center">
        <address class="text-caption text-center text-md-left">
            <strong class="text-uppercase">{{ address.name }}</strong><br>
            {{ address.line }}<br/>
            {{ address.zip }} <span class="text-uppercase">{{ address.city }}</span>
        </address>
    </v-col>

    <!-- contact -->
    <v-col cols="12" sm="6" md="3" class="d-flex flex-column justify-center">
        <div class="text-caption text-center text-md-left">
            <a :href="telhref"><v-icon class="mb-1" small color="white">mdi-phone</v-icon> {{ phone }}</a><br>
            <a :href="mailto"><v-icon class="mb-1" small color="white">mdi-mail</v-icon> {{ email }}</a>
        </div>
<!--        <div class="d-flex justify-center justify-md-start">-->
<!--            <a v-for="(social,index) in socials" :key="index" :href="social.href" target="_blank"><v-icon size="22" class="mr-3">{{ social.icon }}</v-icon></a>-->
<!--        </div>-->
    </v-col>

    <!-- terms -->
    <v-col cols="12" sm="6" md="3">
        <div class="text-caption text-center text-md-left">
            Illustrations: <a :href="illustration.href" target="_blank">{{ illustration.copyright }}</a><br>
            <a @click="$emit('open-legal-dialog')">Mentions légales</a><br>
            <a :href="`Shine_Medical-Conditions_générales_de_vente_annexables.pdf`" download>Conditions générales de vente</a><br>
            @{{ year }} {{ company }},<br class="d-none d-md-inline d-lg-none"> tous droits réservés
        </div>
    </v-col>

</v-row>
</v-container>
</v-footer>
</template>

<script>
import { pathToAsset } from '../mixins/pathToAsset.js'

export default {
    name: 'Footer',

    mixins: [
        pathToAsset
    ],

    data: function(){ return {
        logo: {
            src: "logo_shine_medical_couleur_blanc.png",
            alt: "logo de Shine Medical"
        },

        address: {
            name: "Shine Medical",
            line: "1, rue du Pré Brenot",
            city: "châtillon-le-duc",
            zip: "25870"
        },

        phone: "+33 (0)9 72 58 37 31",
        email: "contact@shine-medical.com",

        socials: [
            {
                icon: "mdi-facebook",
                href: "https://www.facebook.com/Shine-Group-108301137739233"
            },
            {
                icon: "mdi-instagram",
                href: "https://www.instagram.com/shinegrouptech"
            },
            {
                icon: "mdi-linkedin",
                href: "https://www.linkedin.com/company/shinegrouptechnology/"
            }
        ],

        year: "2021",
        company: "Shine Medical",

        illustration: {
            href: "http://www.freepik.com",
            copyright: "Freepik/katemangostar"
        }
    }},

    computed:{
        mailto(){
            return "mailto:"+this.email
        },

        telhref(){
            return "tel:"+this.phone
        }
    }

}
</script>

<style scoped>
a{
    font-style: italic;
}
</style>
