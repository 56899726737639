var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":_vm.partner.href,"target":"_blank"}},[_c('v-card',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.offScreen,
            options: {
                threshold: 0,
                rootMargin: '99999px 260px'
            }
        }),expression:"{\r\n            handler: offScreen,\r\n            options: {\r\n                threshold: 0,\r\n                rootMargin: '99999px 260px'\r\n            }\r\n        }"}],staticClass:"d-flex flex-column mx-15 animated-card",class:{
            'slide':  _vm.running && _vm.partner.visible
        },style:({transform: 'translateX('+(_vm.XPosition)+'px)' , 'animation-duration': _vm.animationDuration}),attrs:{"width":"200","height":"200"}},[_c('v-img',{attrs:{"src":_vm.pathToAsset(_vm.partner.logo.src),"alt":_vm.partner.logo.alt,"contain":"","eager":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }