<template>
<section
    :id="id"
    class="pt-3"
    v-intersect="{
        handler: intersectionHandler,
        options: {
            threshold: 0,
            rootMargin: '-49% 0px -49% 0px'
        }
    }"
>
<v-container>

    <!-- title -->
    <v-row>
        <v-col>
            <h2 class="text-h4 text-sm-h3 font-weight-medium text-center text-lg-left">{{ title }}</h2>
        </v-col>
    </v-row>

    <!-- activities with alternative layout -->
    <section
        v-for="(activity,index) in activities"
        :key="index"
    >
        <Activity
            :activity="activity"
            :reverse="(index+1)%2"
            @devis-click="$emit('devis-click')"
        />
        <v-divider v-if="index!=activities.length-1" inset></v-divider>
    </section>

</v-container>
</section>
</template>

<script>
import Activity from './Activity'
import { intersectionHandler } from '../mixins/intersectionHandler.js'

export default {
    name: 'Activities',

    components: {
        Activity
    },

    mixins: [
      intersectionHandler
    ],

    data: function(){ return {
        id: "activities",

        title: "Nos compétences",

        activities: [
            {
                color: "shineRed",
                title: null,
                htmlDescription: "Shine Medical a recourt à la modélisation en <strong>2D</strong>, en <strong>3D</strong>, à la <strong>réalité virtuelle</strong> et à la <strong>réalité augmentée</strong> dans le développement de vos logiciels médicaux.<br/>Nous utilisons le langage de programmation <strong>C++</strong> et le moteur <strong>Shine Engine</strong>, une technologie propriétaire.",
                images: [
                    {
                        src: "skill_intro.png",
                        alt: "illustration représentant trois personnages en vêtements de personnel hospitalier devant une application géante"
                    }
                ],
                button: {
                    text: "Demander un devis",
                    href: "#"
                },
                logo: null
            },

            {
                color: "shineRed",
                title: 'AR / VR',
                htmlDescription: "La réalité augmentée consiste en l’ajout d’<strong>entités virtuelles</strong> dans le <strong>monde de l’utilisateur</strong>. La réalité virtuelle offre une <strong>expérience immersive</strong> de la personne dans un <strong>monde virtuel</strong>.<br/>En <strong>mobile</strong> ou avec l’usage d’un des <strong>casques</strong> suivants:<ul><li>Hololens 1</li> <li>Hololens 2</li><li>VR oculus</li><li>HTC vive</li></ul>La modélisation d’un environnement en 3D peut donner vie à n’importe quel projet et ce sans limite.",
                images: [
                    {
                        src: "skill_VR.png",
                        alt: "illustration représentant une femme avec un casque de réalité virtuelle au milieu de constellations d'étoiles"
                    }
                ],
                button: null,
                logo: null
            }
        ]

    }}
}
</script>
