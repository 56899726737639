var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"intersect",rawName:"v-intersect",value:({
        handler: _vm.intersectionHandler,
        options: {
            threshold: 0,
            rootMargin: '-49% 0px -49% 0px'
        }
    }),expression:"{\r\n        handler: intersectionHandler,\r\n        options: {\r\n            threshold: 0,\r\n            rootMargin: '-49% 0px -49% 0px'\r\n        }\r\n    }"}],staticClass:"pt-3",staticStyle:{"position":"relative"},attrs:{"id":_vm.id}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"text-h4 text-sm-h3 font-weight-medium text-center text-md-left"},[_vm._v(_vm._s(_vm.title))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5","lg":"6","xl":"5","offset-xl":"1"}},[_c('v-lazy',{staticClass:"d-none d-md-block",attrs:{"options":{
                    threshold: 0,
                    rootMargin: '99999px 0px -120px 0px'
                },"min-height":"240","transition":"scroll-x-transition"}},[_c('div',[_c('v-row',[_c('v-col',[_c('a',{attrs:{"href":_vm.news[0].link.href,"target":"_blank"}},[_c('v-img',{staticClass:"elevation-5",attrs:{"src":_vm.pathToAsset(_vm.news[0].image.src),"alt":_vm.news[0].image.alt,"height":"240","eager":""}})],1)])],1),_c('v-row',[_c('v-col',[_c('h3',{staticClass:"text-h6 mb-3 text-height"},[_vm._v(_vm._s(_vm.news[0].title))]),_c('p',{staticClass:"text-body-2 mb-3 text-justify"},[_vm._v(_vm._s(_vm.news[0].abstract))]),(_vm.news[0].image.copyright)?_c('p',{staticClass:"text-caption mb-0"},[_vm._v(" Crédit photo: "+_vm._s(_vm.news[0].image.copyright)+" ")]):_vm._e(),_c('p',{staticClass:"text-body-2 font-weight-medium mb-0",style:({color: _vm.news[0].link.color})},[_c('a',{attrs:{"href":_vm.news[0].link.href,"target":"_blank"}},[_c('v-icon',{staticClass:"mb-1 mr-1",attrs:{"size":"18","color":_vm.news[0].link.color}},[_vm._v("mdi-open-in-new")]),_vm._v(" L'article sur "+_vm._s(_vm.news[0].link.source)+" ")],1)])])],1)],1)]),_c('div',{staticClass:"d-block d-md-none"},[_c('v-row',[_c('v-col',[_c('v-lazy',{attrs:{"options":{
                                threshold: 0,
                                rootMargin: '9999px 0px -120px 0px'
                            },"min-height":"240","transition":"scroll-x-transition"}},[_c('a',{attrs:{"href":_vm.news[0].link.href,"target":"_blank"}},[_c('v-img',{staticClass:"elevation-5",attrs:{"src":_vm.pathToAsset(_vm.news[0].image.src),"alt":_vm.news[0].image.alt,"height":"240","eager":""}})],1)])],1)],1),_c('h3',{staticClass:"text-h6 my-3 text-height"},[_vm._v(_vm._s(_vm.news[0].title))]),_c('p',{staticClass:"text-body-2 mb-3 text-justify"},[_vm._v(_vm._s(_vm.news[0].abstract))]),(_vm.news[0].image.copyright)?_c('p',{staticClass:"text-caption text-right mb-0"},[_vm._v(" Crédit photo: "+_vm._s(_vm.news[0].image.copyright)+" ")]):_vm._e(),_c('p',{staticClass:"text-body-2 text-right font-weight-medium mb-0",style:({color: _vm.news[0].link.color})},[_c('a',{attrs:{"href":_vm.news[0].link.href,"target":"_blank"}},[_c('v-icon',{staticClass:"mb-1 mr-1",attrs:{"size":"18","color":_vm.news[0].link.color}},[_vm._v("mdi-open-in-new")]),_vm._v(" L'article sur "+_vm._s(_vm.news[0].link.source)+" ")],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"7","lg":"6","xl":"5"}},[_c('SideNews',{attrs:{"news":_vm.news[1]}}),_c('SideNews',{attrs:{"news":_vm.news[2]}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }