<template>
<router-view></router-view>
</template>

<script>
export default {
    name: 'App',
}
</script>

<style>

ul {
  list-style: none;
}

ul li::before {
  content: "\2022";
  color:#ED1847;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

a{
    color: inherit !important;
    text-decoration: none;
}

address{
    font-style: normal;
}
</style>
