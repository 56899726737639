<template>
    <v-row>

        <!-- image -->
        <v-col cols="12" sm="6">
            <v-lazy
                :options="{
                    threshold: 0,
                    rootMargin: '9999px 0px -120px 0px'
                }"
                height="240"
                transition="scroll-x-reverse-transition"
            >
                <a :href="news.link.href" target="_blank">
                    <v-img
                        :src="pathToAsset(news.image.src)" :alt="news.image.alt"
                        height="240"
                        class="elevation-5"
                        eager>
                    </v-img>
                </a>
            </v-lazy>
        </v-col>

        <v-col cols="12" sm="6">

            <!-- animated text sm+ -->
            <v-lazy
                class="d-none d-sm-block"
                :options="{
                    threshold: 0,
                    rootMargin: '99999px 0px -120px 0px'
                }"
                height="240"
                transition="scroll-x-reverse-transition"
            >
                <div class="d-flex flex-column" style="height: 100%">
                    <h3 class="text-h6 mb-3 text-height">{{ news.title }}</h3>
                    <p class="text-body-2 mb-3 text-justify">{{ news.abstract }}</p>
                    <p
                        v-if="news.image.copyright"
                        class="text-caption mb-0"
                    >
                        Crédit photo: {{ news.image.copyright }}
                    </p>
                    <p class="text-body-2 font-weight-medium mb-0" :style="{color: news.link.color}">
                        <a :href="news.link.href" target="_blank">
                            <v-icon class="mb-1 mr-1" size="18" :color="news.link.color">mdi-open-in-new</v-icon>
                            L'article sur {{ news.link.source }}
                        </a>
                    </p>
                </div>
            </v-lazy>

            <!-- text xs -->
            <div class="d-block d-sm-none">
                <h3 class="text-h6 mb-3 text-height">{{ news.title }}</h3>
                <p class="text-body-2 mb-3 text-justify">{{ news.abstract }}</p>
                <p
                    v-if="news.image.copyright"
                    class="text-caption text-right mb-0"
                >
                    Crédit photo: {{ news.image.copyright }}
                </p>
                <p class="text-body-2 font-weight-medium text-right mb-0" :style="{color: news.link.color}">
                    <a :href="news.link.href" target="_blank">
                        <v-icon class="mb-1 mr-1" size="18" :color="news.link.color">mdi-open-in-new</v-icon>
                        L'article sur {{ news.link.source }}
                    </a>
                </p>
            </div>

        </v-col>
    </v-row>
</template>

<script>
import { pathToAsset } from '../mixins/pathToAsset.js'

export default {
    name: 'SideNews',

    mixins: [
        pathToAsset
    ],

    props: {
        news: Object
    }

}
</script>

<style scoped>
.text-height{
    line-height: 130%;
}
</style>
