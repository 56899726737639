<template>
<div>
    <v-app-bar dark app short>

        <!-- menu icon md- -->
        <v-app-bar-nav-icon
            class="d-lg-none"
            @click="drawer=true"
        ></v-app-bar-nav-icon>

        <!-- active section md+ -->
        <v-toolbar-title
            class="d-lg-none text-subtitle-2 text-uppercase"
            style="letter-spacing: 0.0892857143em !important"
        >
            {{ navItems[activeNavItemIndex].name }}
        </v-toolbar-title>

        <!-- navigation tabs lg+ -->
        <v-tabs
            class="d-none d-lg-block"
            :value="activeNavItemIndex"
            color="white"
            grow
        >
            <v-tab
                v-for="(navItem,index) in navItems"
                :key="index"
                active-class="red-background"
                @click="$vuetify.goTo('#'+navItem.id, {duration: 500})"
            >
                {{ navItem.name }}
            </v-tab>
        </v-tabs>

    </v-app-bar>

    <!-- navigation drawer md- -->
    <v-navigation-drawer
        class="d-lg-none"
        v-model="drawer"
        width="210"
        style="z-index: 9;"
        dark
        floating
        app
        temporary
        transition="fab-transition"
    >
    
        <!-- nav items md- -->
        <v-list shaped nav class="pa-5">
            <v-list-item
                v-for="(navItem,index) in navItems"
                :key="index"
                :input-value="activeNavItemIndex==index"
                color="shineRed"
                @click="drawer=false ; $vuetify.goTo('#'+navItem.id,{duration: 500})"
            >
                <v-list-item-title
                    class="text-subtitle-2 text-uppercase"
                    style="letter-spacing: 0.0892857143em !important"
                >
                    {{ navItem.name }}
                </v-list-item-title>
            </v-list-item>
        </v-list>

    </v-navigation-drawer>

</div>
</template>

<script>
export default {
    name: 'NavigationBar',

    props:{
        activeNavItemId: String
    },

    data: function(){ return {
        drawer: false,

        navItems: [
            {
                name: "Accueil",
                id: "home"
            },
            {
                name: "Nos compétences",
                id: "activities"
            },
            // {
            //     name: "Nos réalisations",
            //     id: "projects"
            // },
            {
                name: "Nos actualités",
                id: "news"
            },
            {
                name: "Nos clients",
                id: "partners"
            },
            {
                name: "Nous contacter" ,
                id: "contact"
            }
        ]
    }},

    computed:{
        activeNavItemIndex(){
            return this.navItems.findIndex((navItem)=> navItem.id==this.activeNavItemId)
        }
    }
    
}
</script>

<style scoped>
.v-app-bar{
    z-index: 8 !important;
}

.red-background {
    background-color: rgba(237,24,71,0.85);
}
</style>